<template>
    <div>
        <!-- Sidebar navigation -->
        <div class="sidebar-navigation" 
            @mouseenter="expandMenu" 
            @mouseleave="collapseMenu"
            @click.stop
        >
            <div class="top-container">
                <div @click="goToHome">
                    <img src="/img/logo-white.png" alt="Logo" class="logo-white">
                </div>
                <div class="snb-button" @click="goToSettings">
                    <font-awesome-icon icon="cog" class="menu-ico" />
                    <span v-if="isExpanded" class="menu-text">Settings</span>
                </div>
                <div v-if="isAdminVisible" class="snb-button" @click="goToStats">
                    <font-awesome-icon icon="chart-bar" class="menu-ico" />
                    <span v-if="isExpanded" class="menu-text">Stats</span>
                </div>
                <div class="snb-button" @click="goToUsers">
                    <font-awesome-icon icon="users" class="menu-ico" />
                    <span v-if="isExpanded" class="menu-text">Users</span>
                </div>
            </div>
            <div class="bottom-container">
                <div v-if="isAdminVisible" class="snb-button" @click="goToAdministration">
                    <font-awesome-icon icon="toolbox" class="menu-ico" />
                    <span v-if="isExpanded" class="menu-text">Admin</span>
                </div>
                <div class="snb-button" @click="logout">
                    <font-awesome-icon icon="sign-out-alt" class="menu-ico" />
                    <span v-if="isExpanded" class="menu-text">Logout</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import requests from "@/lib/requests";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSquare, faSignOutAlt, faCog, faChartBar, faUsers, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { getToken } from "@/lib/auth";

library.add(faSquare, faSignOutAlt, faCog, faChartBar, faUsers, faToolbox);

export default {
    name: 'SidebarNavigation',
    components: {
        FontAwesomeIcon
    },
    data() {
        return {
            isExpanded: false,
            permissionsGroups: []
        };
    },
    created() {
        this.fetchPermissionsGroups();
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        async fetchPermissionsGroups() {
            try {
                const token = getToken();
                if (!token) {
                    this.$router.push('/login');
                    return;
                }

                const response = await requests.get("permissions/user/group", {}, token);
                this.permissionsGroups = response.data;
            } catch (error) {
                console.error('Error fetching permissions groups:', error);
            }
        },
        logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('tokenExpiration');
            this.$router.push('/login');
        },
        goToSettings() {
            this.$router.push('/profile');
        },
        goToStats() {
            // Handle navigation to stats page
        },
        goToUsers() {
            this.$router.push('/users/dashboard');
        },
        goToAdministration() {
            this.$router.push('/admin/dashboard');
        },
        goToHome() {
            this.$router.push('/');
        },
        expandMenu() {
            this.isExpanded = true;
        },
        collapseMenu() {
            this.isExpanded = false;
        },
        handleClickOutside(event) {
            const sidebar = this.$el.querySelector('.sidebar-navigation');
            if (sidebar && !sidebar.contains(event.target)) {
                this.collapseMenu();
            }
        }
    },
    computed: {
        isAdminVisible() {
            return this.permissionsGroups.some(group => group.name === 'admin');
        }
    }
};
</script>

<style scoped>
.sidebar-navigation {
    height: 100vh;
    width: 60px;
    background-color: #6F2A6F;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
    transition: width 0.3s ease;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.sidebar-navigation:hover {
    width: 150px;
}

.bottom-container {
    display: flex;
    flex-direction: column;
}

.snb-button {
    color: white;
    font-size: 3vh;
    transition: color 0.8s ease;
    text-align: center;
    margin-top: 10px;
    height: 45px;
}

.snb-button:hover {
    color: black;
    cursor: pointer;
    font-weight: bold;
}

.snb-button:hover .menu-text {
    color: black;
}

.menu-text {
    color: white;
    font-size: 1rem;
    margin-left: 5px;
    transition: opacity 0.3s ease;
    display: inline-block;
}

.menu-ico {
    float: left;
    margin-left: 10px;
    margin-top: 9px;
}

.menu-text:hover {
    cursor: pointer;
}

.top-container {
    margin-top: 20px;
}

.logo-white {
    width: 48px;
    margin: auto;
    margin-bottom: 20px;
}

.logo-white:hover {
    cursor: pointer;
}
</style>
