<template>
  <div class="tpi-view">
    <h4>TPI View for TPI ID: {{ tpiId }}</h4>

    <div v-if="tableData.length">
      <div class="table-responsive mt-3">
        <table class="table table-bordered">
          <tbody>
            <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
              <td>{{ verticalLabels[rowIndex] }}</td>
              <td
                v-for="(cell, cellIndex) in row"
                :key="`${rowIndex}-${cellIndex}`"
                :class="[cell.class, cell.topBorder]"
              >
                {{ cell.value }}
              </td>
            </tr>
            <tr>
              <th></th>
              <th
                v-for="(value, valueIndex) in formattedHorizontalValues"
                :key="valueIndex"
              >
                {{ value }}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      <p>No zone data available for this TPI.</p>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, watch, computed } from "vue";
import requests from "@/lib/requests";
import { getToken } from "@/lib/auth";

export default {
  name: "TPIUserView",
  props: {
    tpiId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const verticalLabels = [
      "V16",
      "V15",
      "V14",
      "V13",
      "V12",
      "V11",
      "V10",
      "V9",
      "V8",
      "V7",
      "V6",
      "V5",
      "V4",
      "V3",
      "V2",
      "V1",
    ];

    const tableData = reactive(
      Array.from({ length: 16 }, () =>
        Array.from({ length: 16 }, () => ({
          value: "",
          class: "white", // Default class set to white
          topBorder: "",
        }))
      )
    );

    const errorMessage = ref("");
    const centerFrequencies = ref([]);

    const formattedHorizontalValues = computed(() =>
      centerFrequencies.value.map((freq) =>
        freq >= 1000 ? `${freq / 1000}K` : freq.toString()
      )
    );

    const fetchTPIData = async () => {
      try {
        const response = await requests.get(
          `/tpi/view/user-tpi/${props.userId}/${props.tpiId}`,
          {},
          getToken()
        );
        if (response && response.data) {
          centerFrequencies.value =
            response.data.grid_config?.center_frequencies || [];
          processActions(response.data.band_data);
        } else {
          errorMessage.value = "No TPI data found.";
        }
      } catch (error) {
        errorMessage.value = "An error occurred while fetching TPI data.";
      }
    };

    const processActions = (bandData) => {
      tableData.forEach((row) =>
        row.fill({ value: "", class: "white", topBorder: "" })
      );

      bandData.forEach((band) => {
        const {
          band_index,
          min,
          max,
          inaudible,
          painful,
          sensible_zones,
        } = band;

        if (min !== null && max !== null) placeZone(band_index, min, max, "green");

        // Handle null `inaudible` or `painful`
        if (inaudible === null && min !== null) {
          placeZone(band_index, 1, min - 1, "very-low");
        }
        if (painful === null && max !== null) {
          placeZone(band_index, max + 1, 16, "annoying");
        }

        if (inaudible !== null) placeZone(band_index, inaudible, 1, "inaudible");
        if (min !== null && inaudible !== null && min - inaudible > 1)
          placeZone(band_index, inaudible + 1, min - 1, "very-low");
        if (painful !== null) placeZone(band_index, painful, 16, "painful");
        if (max !== null && painful !== null && painful - max > 1)
          placeZone(band_index, max + 1, painful - 1, "annoying");

        // Properly handle sensible zones
        placeZonesSequentially(band_index, sensible_zones, "sensible");
      });
    };

    const placeZonesSequentially = (bandIndex, zones, zoneClass) => {
      if (!zones || zones.length === 0) return;

      zones.forEach((zone, index) => {
        const { quietest_volume, loudest_volume } = zone;
        placeZone(
          bandIndex,
          loudest_volume,
          quietest_volume,
          zoneClass,
          index === 0 // First zone gets no top border
        );
      });
    };

    const placeZone = (bandIndex, start, end, zoneClass, isFirstZone = false) => {
      const cellIndex = bandIndex - 1;
      const lower = Math.min(start, end);
      const upper = Math.max(start, end);

      for (let y = upper; y >= lower; y--) {
        const rowIndex = 16 - y;
        if (rowIndex < 0 || rowIndex >= tableData.length) continue;

        tableData[rowIndex][cellIndex] = {
          value: "",
          class: zoneClass,
          topBorder:
            !isFirstZone && y !== upper ? `border-top-${zoneClass}` : "",
        };

        isFirstZone = false; // Ensure subsequent cells in the same zone get borders
      }
    };

    watch(
      () => props.tpiId,
      (newTpiId) => {
        if (newTpiId) fetchTPIData();
      }
    );

    onMounted(() => {
      fetchTPIData();
    });

    return {
      verticalLabels,
      formattedHorizontalValues,
      tableData,
      errorMessage,
    };
  },
};
</script>

<style scoped>
.table-bordered {
  width: 100%;
  table-layout: fixed;
}

.green {
  background-color: #12b76a;
}

.white {
  background-color: #ffffff;
}

.inaudible {
  background-color: #667085;
}

.very-low {
  background-color: #98A2B3;
}

.painful {
  background-color: #F97066;
}

.annoying {
  background-color: #FFBF83;
}

.sensible {
  background-color: #2450b0;
}

.dubious {
  background-color: #6172F3;
}

.border-top-sensible {
  border-top: 2px solid #2450b0;
}

.border-top-dubious {
  border-top: 2px solid #6172F3;
}
</style>
