<template>
  <div class="tpi-selector white-box">
    <h4>TPI Data</h4>

    <div class="tpi-options">
      <div
        v-for="index in 3"
        :key="index"
        class="tpi-option"
        :class="{ 'tpi-option-disabled': !tpiData[index - 1] }"
        @click="tpiData[index - 1] ? selectTpi(tpiData[index - 1]) : null"
      >
        <p><strong>TPI {{ index }}</strong></p>
        <p v-if="tpiData[index - 1]">{{ formatDate(tpiData[index - 1].created_at) }}</p>
        <p v-else>No Data</p>
      </div>
    </div>

    <div v-if="selectedTpiDetails" class="tpi-view-container mt-4">
      <h5>TPI Details</h5>
      <p><strong>Start Date:</strong> {{ formatDate(selectedTpiDetails.start_date) }}</p>
      <p><strong>Completion:</strong> {{ selectedTpiDetails.completion }}%</p>

      <TPIUserView :tpi-id="selectedTpiId" :user-id="id" />
    </div>
    <div v-else-if="!tpiData.length">
      <p>No TPI data available for this cycle.</p>
      <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import requests from "@/lib/requests";
import { getToken } from "@/lib/auth";
import TPIUserView from "@/components/user/TPIUserView.vue";

export default {
  name: "TpiSelector",
  components: {
    TPIUserView,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    cycleId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const tpiData = ref([]);
    const selectedTpiId = ref(null);
    const selectedTpiDetails = ref(null);
    const errorMessage = ref("");

    const fetchTpiData = async () => {
      try {
        const response = await requests.get(
          `/cycle/user/${props.id}/cycle/${props.cycleId}`,
          {},
          getToken()
        );
        if (response && response.data.tpis) {
          tpiData.value = response.data.tpis;
        } else {
          errorMessage.value = "No TPI data found.";
        }
      } catch (error) {
        errorMessage.value = "An error occurred while fetching TPI data.";
        console.error(error);
      }
    };

    const selectTpi = (tpi) => {
      selectedTpiId.value = tpi.tpi_id;
      selectedTpiDetails.value = tpi;
    };

    const formatDate = (date) => new Date(date).toLocaleString();

    onMounted(() => {
      fetchTpiData();
    });

    return {
      tpiData,
      selectedTpiId,
      selectedTpiDetails,
      errorMessage,
      fetchTpiData,
      selectTpi,
      formatDate,
    };
  },
};
</script>

<style scoped>
.tpi-options {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.tpi-option {
  flex: 1;
  max-width: 150px; /* Set a consistent width */
  height: 150px; /* Set a consistent height */
  background-color: #007bff;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-align: center;
}

.tpi-option:hover {
  transform: scale(1.05);
}

.tpi-option-disabled {
  background-color: #6c757d;
  cursor: not-allowed;
  pointer-events: none;
}

.tpi-option p {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.tpi-view-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.white-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.text-danger {
  color: #dc3545;
}
</style>
